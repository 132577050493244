import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */





function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={graphql`
          query {
            imageSearch: file(relativePath: { eq: "press/search.jpg" }) {
              ...fluidImage
            }
            imageAnimus: file(relativePath: { eq: "press/animus.jpg" }) {
                ...fluidImage
              }
            imageAssembly: file(relativePath: { eq: "press/assembly.jpg" }) {
              ...fluidImage
            }

            imageBoard: file(relativePath: { eq: "press/board.jpg" }) {
                ...fluidImage
              }

            imageZurich: file(relativePath: { eq: "press/zurich.jpg" }) {
                ...fluidImage
            }
          }
      `}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const ImageSearch = withImageData(props => (
  <Img fluid={props.imageData.imageSearch.childImageSharp.fluid} />
));
const ImageAnimus = withImageData(props => (
  <Img fluid={props.imageData.imageAnimus.childImageSharp.fluid} />
));
const ImageAssembly = withImageData(props => (
  <Img fluid={props.imageData.imageAssembly.childImageSharp.fluid} />
));
const ImageBoard = withImageData(props => (
  <Img fluid={props.imageData.imageBoard.childImageSharp.fluid} />
));
const ImageZurich = withImageData(props => (
  <Img fluid={props.imageData.imageZurich.childImageSharp.fluid} />
));


export { ImageSearch, ImageAnimus, ImageAssembly, ImageBoard, ImageZurich };
