import React from 'react'
import {Wrap, SiteTitle} from "../components/basics"

import {ImageSearch, ImageAnimus, ImageAssembly, ImageBoard, ImageZurich} from "../components/glazialcom/press_images"

import GlazialLayout from "../components/glazialcom/glazial_layout"

import {PanopticTestGradient, GesichtGradient} from "../components/glazialcom/glazialComponents"







export default (props) =>
<>

<SiteTitle string="GLAZIAL GROUP" />





<GlazialLayout>

<Wrap>









<div className="pressPost">
<section>
    <a id="gesichtDevelopment" href="none">
    </a>
</section>

<GesichtGradient />

<h2>GESICHT Development

</h2>
<p>
The Glazial IT staff restarted development of the Gesicht software. The prototype originally developed by Prof. Sigrist’s AI department is a management tool, integrated in our firm’s IT system.
<br/><br/>

Part of the Gesicht AI will also be integrated in the upcoming holonoptic™ test to determine the potential CEO candidates’ ability to deal with the software.

</p>
</div>






<div className="pressPost">
<section>
    <a id="panopticDevelopment" href="none">
    </a>
</section>



<PanopticTestGradient />

<h2>potential CEO candidates evaluated by new software
</h2>
<p>
As demanded by several shareholders and members of the board, the new CEO will be preselected by a new CEO testing software, currently in development by our IT staff lead by Mr Roth, CTO of the Glazial Group and a new board member.
<br/><br/>

Organizational change is crucial to the future of the firm, especially concerning the role of leading management. The previous CEO possessed almost omnipotent powers, much to the disadvantage of the firm. The CEO TEST will evaluate potential candidates by their ability to deal with complexity as well as how they define their role of CEO.

</p>
</div>


 












<div className="pressPost">
<section>
    <a id="newPresident" href="none">
    </a>
</section>

<ImageBoard/>

<h2>Board appoints new chairman/ Former CEO officially let go</h2>
<p>
The board of directors elected Sepp Leiser as new chairman and temporarily acting CEO. They also terminated the contract of the now former Glazial CEO Laurent Oprescu in his continuing absence.
<br/><br/>

The reasons for terminating Laurent Oprescu’s are not limited to his prolonged absence. As heir, controlling shareholder, CEO and president, Mr Oprescu made some questionable decisions, such as selling off almost all assets of the firm, leaving only its holding structure. While he initiated several mergers, it is unclear whether they will conclude, given the current state of the company.
<br/><br/>

A new CEO will be found as quickly as possible.
</p>
</div>
 



 









<div className="pressPost">
<section>
    <a id="newBoard" href="none">
    </a>
</section>

<ImageAssembly/>


<h2>General assembly appoints board of directors</h2>
<p>
Last week’s annual general assembly took place in absence of CEO Laurent Oprescu, heir of the controlling the stake of the company. The absence of the single majority shareholder changed the usual process of the general assembly. 
<br/><br/>
Newly elected to the board are:
<br/><br/>

- Sepp Leiser, owner and CEO of Capital Singularity, a hedgefund<br/>
- Jon Roth, CTO of the Glazial Group

</p>
</div>









<div className="pressPost">
<section>
    <a id="CEOabsence" href="none">
    </a>
</section>



<ImageSearch/>


<h2>Glazial CEO not vanished</h2>
<p>
After multiple days of REGA search, several news reports understandibly state that our CEO has vanished in the Engadin Swiss Alps. However, these reports are false.
<br/><br/>

CEO Laurent Oprescu recently contacted GLAZIAL management, instructing them to take care of the company during his unspecified absence.
</p>
</div>







<div className="pressPost">
<section>
    <a id="animus" href="none">
    </a>
</section>


<ImageAnimus/>


<h2>Artificial Intelligence department sold for 672 million CHF</h2>
<p>
The Glazial Group successfully completed the sale of its subsidiary Glazial AI, a research and development company focused on artificial intelligence. 
<br/><br/>

Today the Finland-based acquirer Animus, a tech company, announced the end of the tender offer to purchase all outstanding Animus-shares for 6.50 CHF per share.
<br/><br/>

Prof. Sigrist, former head of the AI department, resigned from her position as CSO of Glazial, announcing to seek opportunities elsewhere.
</p>
</div>






<div className="pressPost">
<section>
    <a id="capitalExpansion" href="none">
    </a>
</section>

<ImageZurich/>


<h2>Generating Growth Capital</h2>
<p>
The Glazial Group responds to erroneous reports claiming that this company is in the “process of liquidation”.
<br/><br/>

During the past year, Glazial has gone through a slimming restructuring process. In the course of this, a series of subsidiaries and departments were sold off, lastly our AI department.
<br/><br/>

The goal of these divestitures was to raise capital in order to make new acquisitions, preparing our next expansion cycle. The Glazial Group is thus completely aligned with its long term strategy.
<br/><br/>

Glazial’s CEO Laurent Oprescu is already engaged in several M&A negotiations with new companies, which will be announced in due time.
</p>
</div>




</Wrap>

</GlazialLayout>

  

</>




